export default {
  homeBannerTxt: '一家专注于应用软件产品开发和运营的高科技创新企业。目前已累积用户量超3亿，拥有80余项自主知识产权，获得专利共4项，其中发明专利3项。',
  home: '首页',
  team: '团队',
  product: '产品',
  recruitment: '招聘',
  contacts: '联系我们',
  about: '关于',
  homeUs: '我们',
  wnlTitle: '万年历',
  wnlRemark: '三亿人都在使用的日历APP',
  xdrcTitle: '心动日常',
  xdrcRemark: '记录恋爱中的点滴',
  aiTitle: 'EasyArt',
  aiRemark: '使用EasyArt创造令人惊艳的艺术作品',
  dhTitle: '死亡扩散：生死时刻',
  dhRemark: '率领你的特战小队，去突破重重尸潮，寻找希望的曙光！',
  mtTitle: '最强巨塔',
  mtRemark: '召集公会战友，对抗敌军势力，称霸浩瀚星系！',
  mt2Title: '最强巨塔2 ：星舰出航',
  mt2Remark: '探索宇宙、保卫星际之旅！',
  our: '我们的',
  products: '产品',
  life: '生活',
  activities: '活动',
  nineYears: '九周年',
  hKJourney: '香港旅游',
  sports: '运动',
  birthdayParty: '生日会',
  welcomeToJoin: '欢迎加入',
  Calendar: '可兰达',
  administrativeExecutive: '行政主管',
  marketingExecutive: '营销主管',
  PartnershipandMarketingExecutive: '营销主管',
  viewMore: '查看更多',
  joinText1: '请将简历发送至邮箱：',
  joinText2: '（邮件标题：应聘公司-职位-姓名）',
  joinText3: '我们期待你的加入！',
  address: '地址:',
  tel: '电话:',
  email: '邮箱:',
  contact: '联系',
  us: '我们'
}